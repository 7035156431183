<template>
  <v-container
    id="login"
    fill-height
    tag="section"
  >
    <v-row justify="center">
      <v-col cols="12" md="3" sm="3" xs="3"></v-col>
      <v-col cols="12" md="6" sm="6" xs="6" style="text-align: center;" class="pa-0 ma-0">
        <!--<img src="@/assets/images/cxAppLogo.png" style="height: 80px;margin-bottom: 20px"/>-->
        <!--<img :src="this.logoImg" :class="{'campus':this.client=='CX Campus','clorox':this.client=='Clorox'}"/>-->
        <img v-if="showLogoPic=='normal'" :src="this.logoImg" class="loginImg"
             :class="{'campus':this.client=='CX Campus','clorox':this.client=='Clorox'}"/>
        <img v-if="showLogoPic=='natwest'" :src="this.logoImg" class="natWestLogo"/>
        <div v-if="showLogoPic=='clorox'">
          <span style="font-size: 30px;color:#000;font-weight:bold;">myDay</span>
        </div>
        <div v-if="showLogoPic=='aruba'">
          <span style="font-size: 30px;color:#000;font-weight:bold;">Aruba</span>
        </div>
        <div v-if="showLogoPic=='warnermedia'">
          <span style="font-size: 30px;color:#000;font-weight:bold;">WM Red Carpet</span>
        </div>
        <div v-if="showLogoPic=='hpe'">
          <span style="font-size: 30px;color:#000;font-weight:bold;">HPE</span>
        </div>
        <div v-if="showLogoPic=='adobe'">
          <span style="font-size: 30px;color:#000;font-weight:bold;">Adobe</span>
        </div>
        <div v-if="showLogoPic=='guardant'">
          <span style="font-size: 30px;color:#000;font-weight:bold;">Guardant</span>
        </div>
        <div v-if="showLogoPic=='pinterest'">
          <span style="font-size: 30px;color:#000;font-weight:bold;">Pinterest</span>
        </div>
        <div v-if="showLogoPic=='pfizer'">
          <span style="font-size: 30px;color:#000;font-weight:bold;">Pfizer</span>
        </div>
        <div v-if="showLogoPic=='appliedMaterials'">
          <span style="font-size: 30px;color:#000;font-weight:bold;">Applied Materials</span>
        </div>
        <div v-if="showLogoPic=='rivian'">
          <span style="font-size: 30px;color:#000;font-weight:bold;">Rivian</span>
        </div>
        <div v-if="showLogoPic=='KLA'">
          <span style="font-size: 30px;color:#000;font-weight:bold;">KLA</span>
        </div>
        <div v-if="showLogoPic=='doorDash'">
          <span style="font-size: 30px;color:#000;font-weight:bold;">DoorDash</span>
        </div>
        <div v-if="showLogoPic=='lenovo'">
          <span style="font-size: 30px;color:#000;font-weight:bold;">Lenovo</span>
        </div>
        <div v-if="showLogoPic=='nutanix'">
          <span style="font-size: 30px;color:#000;font-weight:bold;">Nutanix</span>
        </div>
        <div v-if="showLogoPic=='Metlife'">
          <span style="font-size: 30px;color:#000;font-weight:bold;">MetLife</span>
        </div>
        <div v-if="showLogoPic=='IMD Campus'">
          <span style="font-size: 30px;color:#000;font-weight:bold;">IMD Campus</span>
        </div>
        <div v-if="showLogoPic=='EA'">
          <span style="font-size: 30px;color:#000;font-weight:bold;">EA</span>
        </div>
          <div v-if="showLogoPic=='Cloudflare'">
          <span style="font-size: 30px;color:#000;font-weight:bold;">Cloudflare</span>
        </div>  
         <div v-if="showLogoPic=='Pepsico'">
          <span style="font-size: 30px;color:#000;font-weight:bold;">Pepsico</span>
        </div>
        <div v-if="showLogoPic=='Bath University'">
          <span style="font-size: 30px;color:#000;font-weight:bold;">Bath University</span>
        </div>
        <img v-if="showLogoPic=='edwards'" :src="this.logoImg" style="width:110px"
             class="edwardsLogo"/>
      </v-col>
      <v-col cols="12" md="3" sm="3" xs="3"></v-col>
      <v-slide-y-transition appear>

        <base-material-card
          color="success"
          light
          max-width="100%"
          width="400"
          class="px-5 py-3"
        >
          <template v-slot:heading>
            <div class="text-center">
              <h1 class="loginBar display-2 font-weight-bold mb-2">
                Login
              </h1>

              <!--<v-btn-->
              <!--v-for="(social, i) in socials"-->
              <!--:key="i"-->
              <!--:href="social.href"-->
              <!--class="ma-1"-->
              <!--icon-->
              <!--rel="noopener"-->
              <!--target="_blank"-->
              <!--&gt;-->
              <!--<v-icon-->
              <!--v-text="social.icon"-->
              <!--/>-->
              <!--</v-btn>-->
            </div>
          </template>

          <v-card-text class="text-center">
            <!--<div class="text-center grey&#45;&#45;text body-1 font-weight-light">-->
            <!--Or Be Classical-->
            <!--</div>-->

            <!--<v-text-field-->
            <!--color="secondary"-->
            <!--label="First Name..."-->
            <!--prepend-icon="mdi-face"-->
            <!--class="mt-10"-->
            <!--/>-->

            <!--<v-text-field-->
            <!--color="secondary"-->
            <!--label="Email..."-->
            <!--prepend-icon="mdi-email"-->
            <!--v-model="email"-->
            <!--@keyup.enter="login"-->
            <!--/>-->

            <v-text-field
              color="secondary"
              label="Password"
              prepend-icon="mdi-lock-outline"
              type="password"
              v-model="password"
              tabindex="1"
              @keyup.enter="login"
            />
            <div style="margin: 20px">
              <span @click="forgot" tabindex="2" @keyup.enter="forgot"
                    style="color:#000000;cursor: pointer;">Forgot your password?</span>
            </div>

            <pages-btn
              large
              color=""
              depressed
              class="x-btn"
              tabindex="3"
              @click="login"
            >
              Login
            </pages-btn>
          </v-card-text>
        </base-material-card>
      </v-slide-y-transition>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import {mapGetters, mapMutations} from 'vuex';

export default {
  name: 'PagesLogin',

  components: {
    PagesBtn: () => import('./components/Btn')
  },
  computed: {
    ...mapGetters('avator', ['getAvator']),

  },
  data: () => ({
    showLogoPic: '',
    socials: [
      {
        href: '#',
        icon: 'mdi-facebook-box'
      },
      {
        href: '#',
        icon: 'mdi-twitter'
      },
      {
        href: '#',
        icon: 'mdi-github-box'
      }
    ],
    password: '',
    logoImg: "",
    client: "",
  }),

  methods: {
    ...mapMutations('avator', ["setAvator"]),
    // getClient(){
    //   this.getClientData().then(res=>{
    //     this.client = res.data.Client;
    //     console.log("client:",this.client);
    //     if(res.data.Client=='Clorox'){
    //       this.logoImg = require('@/assets/images/cloroxLogo.png')
    //     }else{
    //       this.logoImg = require('@/assets/images/cxAppLogo.png')
    //     }
    //   }).catch(err=>{
    //     console.log(err);
    //   });
    // },
    getClient() {
      this.getClientData().then(res => {
        this.client = res.data.Client;
        console.log("client:", this.client);
        if (res.data.Client == 'Clorox') {
          this.showLogoPic = 'clorox';
        } else if (res.data.Client == 'NatWest') {
          this.showLogoPic = 'natwest';
          this.logoImg = require('@/assets/images/NatWest-Group.jpg');
        } else if (res.data.Client == 'WarnerMedia') {
          this.showLogoPic = 'warnermedia';
          document.title = "WM Red Carpet"
        } else if (res.data.Client == 'Aruba') {
          this.showLogoPic = 'aruba';
          document.title = "Aruba"
        } else if (res.data.Client == 'adobe') {
          this.showLogoPic = 'adobe';
          document.title = "Adobe"
        } else if (res.data.Client == 'Guardant') {
          this.showLogoPic = 'guardant';
          document.title = "Guardant"
        } else if (res.data.Client == 'PinterestCampus') {
          this.showLogoPic = 'pinterest';
          document.title = "Pinterest"
        } else if (res.data.Client == 'Lenovo Corporate') {
          this.showLogoPic = 'lenovo';
          document.title = "Lenovo"
        } else if (res.data.Client == 'HPE' || res.data.Client == 'HPE Campus') {
          this.showLogoPic = 'hpe';
          document.title = "Hpe"
        } else if (res.data.Client == 'Applied Materials') {
          this.showLogoPic = 'appliedMaterials';
          document.title = "Applied Materials"
        } else if (res.data.Client == 'Pfizer') {
          this.showLogoPic = 'pfizer';
          document.title = "Pfizer"
        } else if (res.data.Client == 'Edwards Life Sciences') {
          this.showLogoPic = 'edwards';
          this.logoImg = require('@/assets/images/EdwardsLogo.png');
          document.title = "Edwards"
        } else if (res.data.Client == 'Rivian') {
          this.showLogoPic = 'rivian';
          document.title = "Rivian"
        } else if (res.data.Client == 'KLA') {
          this.showLogoPic = 'KLA';
          document.title = "KLA"
        } else if (res.data.Client == 'DoorDash') {
          this.showLogoPic = 'doorDash';
          document.title = "DoorDash"
        } else if (res.data.Client == 'Nutanix') {
          this.showLogoPic = 'nutanix';
          document.title = "Nutanix"
        } else if (res.data.Client == 'Metlife') {
          this.showLogoPic = 'Metlife';
          document.title = "Metlife"
        } else if (res.data.Client == 'IMD Campus') {
          this.showLogoPic = 'IMD Campus';
          document.title = "IMD Campus"
        }else if (res.data.Client == 'EA') {
          this.showLogoPic = 'EA';
          document.title = "EA"
        }else if (res.data.Client == 'Cloudflare') {
          this.showLogoPic = 'Cloudflare';
          document.title = "Cloudflare"
        }else if (res.data.Client == 'Pepsico') {
          this.showLogoPic = 'Pepsico';
          document.title = "Pepsico"
        }else if (res.data.Client == 'Bath University') {
          this.showLogoPic = 'Bath University';
          document.title = "Bath University"
        } else {
          this.showLogoPic = 'normal';
          this.logoImg = require('@/assets/images/cxAppLogo.png');
        }
      }).catch(err => {
        console.log(err);
      });
    },
    getCaption(obj) {
      if (obj) {
        const index = obj.lastIndexOf("=");
        obj = obj.substring(index + 1, obj.length);
      }
      return obj;
    },
    login() {
      // let b = '';
      // console.log(this.$route.query);
      // console.log(this.$route.query.redirect);
      // if (this.$route.query.redirect) {
      //   b = this.getCaption(this.$route.query.redirect);
      //   console.log(b);
      // } else {
      //   b = this.$route.query.meetingId;
      //   console.log(b);
      // }

      let _this = this;
      let url = "";
      let data = {};
      url = "m/authenticate/";
      data.username = localStorage.userEmail;
      data.password = this.password;
      this.bus.$emit('loading', true);
      if(localStorage.selectRegionLink != ''){
        url = localStorage.selectRegionLink + "/m/authenticate/"
        axios
          .post(url, data)
          .then((response) => {
            console.log('loginInformation', response);
            _this.bus.$emit('loading', false);
            if (response.data.result === 'succeeded') {
              _this.$message.success("Success");
              let link = localStorage.selectRegionLink + '/map#/pages/loginEmail?token=' + response.data.token;
              console.log(response.data.token);
              window.location.replace(link);
            }else{
              this.$confirm({title: "Confirmation", message: response.data.error, show: true});
            }
          })
          .catch((err) => {
            console.log(err);
            _this.bus.$emit('loading', false);
          });
      }else{
        axios
          .post(url, data)
          .then((response) => {
            console.log('loginInformation', response);
            _this.bus.$emit('loading', false);
            if (response.data.result === 'succeeded') {
              
              _this.$message.success("Success");
              localStorage.token = response.data.token;
              // localStorage.userEmail = response.data.email;
              localStorage.userId = response.data.userId;
              _this.getUserAttendees(localStorage.userId);
              localStorage.userName = response.data.firstName + ' ' + response.data.lastName;
              localStorage.image = response.data.image;
              _this.setAvator(response.data.image);
              console.log("getAvator:", this.getAvator);


              _this.$router.push({
                path: "/mec",
              }).catch(err => {
                console.log(err);
              });

              // if (b !== '') {
              //   localStorage.meetingIdMap = b;
              // }
              // if (localStorage.meetingIdMap) {
              //   _this.$router.push({
              //     path: "/mec",
              //   }).catch(err => {
              //     console.log(err);
              //   });
              // } else {
              //   _this.$router.push('/').catch(err => {
              //     console.log(err);
              //   });
              // }
            } else {
              this.$confirm({title: "Confirmation", message: response.data.error, show: true});
              // _this.$message.error(response.data.error);
            }

          })
          .catch((err) => {
            console.log(err);
            _this.bus.$emit('loading', false);
          });
      }

    },
    forgot() {
      let _this = this;
      let url = "";
      let data = {};
      url = "m/forgotPassword/";
      data.email = localStorage.userEmail;
      axios
        .post(url, data)
        .then((response) => {
          if (response.data.result === 'succeeded') {
            this.$confirm({
              title: "Confirmation",
              message: "We've sent you an email to update your password. If you don't see it in your inbox, please check your Spam folder.",
              show: true
            });
          } else {
            this.$confirm({title: "Confirmation", message: response.data.error, show: true});
            // _this.$message.error(response.data.error);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  },
  created() {
    this.getClient();
    if(localStorage.selectRegionLink != ''){
      let url = localStorage.selectRegionLink + '/m/config/';
       axios
        .get(url)
        .then(response => {
          // console.log('version', response);
          localStorage.config = JSON.stringify(response.data);
          // _this.bus.$emit("loading", false);
        })
        .catch(err => {
          console.log(err);
          // _this.bus.$emit("loading", false);
        });
    }
  }
}
</script>
<style lang="scss" scoped>
#login {
  .clorox {
    width: 340px;
  }

  .loginImg {
    width: 80px;
  }

  .natWestLogo {
    width: 200px
  }
}
</style>
